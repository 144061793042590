import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SuggestWorkOrderTextMutationVariables = Types.Exact<{
  suggestWorkOrderTextInput: Types.SuggestWorkOrderTextInput;
}>;


export type SuggestWorkOrderTextMutation = { __typename?: 'Mutation', suggestWorkOrderText: string };

export type SuggestKnowledgeBaseMutationVariables = Types.Exact<{
  question: Types.Scalars['String']['input'];
}>;


export type SuggestKnowledgeBaseMutation = { __typename?: 'Mutation', suggestKnowledgeBase: string };


export const SuggestWorkOrderTextDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SuggestWorkOrderText"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"suggestWorkOrderTextInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestWorkOrderTextInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestWorkOrderText"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"suggestWorkOrderTextInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"suggestWorkOrderTextInput"}}}]}]}}]} as unknown as DocumentNode;
export type SuggestWorkOrderTextMutationFn = Apollo.MutationFunction<SuggestWorkOrderTextMutation, SuggestWorkOrderTextMutationVariables>;

/**
 * __useSuggestWorkOrderTextMutation__
 *
 * To run a mutation, you first call `useSuggestWorkOrderTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestWorkOrderTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestWorkOrderTextMutation, { data, loading, error }] = useSuggestWorkOrderTextMutation({
 *   variables: {
 *      suggestWorkOrderTextInput: // value for 'suggestWorkOrderTextInput'
 *   },
 * });
 */
export function useSuggestWorkOrderTextMutation(baseOptions?: Apollo.MutationHookOptions<SuggestWorkOrderTextMutation, SuggestWorkOrderTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuggestWorkOrderTextMutation, SuggestWorkOrderTextMutationVariables>(SuggestWorkOrderTextDocument, options);
      }
export type SuggestWorkOrderTextMutationHookResult = ReturnType<typeof useSuggestWorkOrderTextMutation>;
export type SuggestWorkOrderTextMutationResult = Apollo.MutationResult<SuggestWorkOrderTextMutation>;
export type SuggestWorkOrderTextMutationOptions = Apollo.BaseMutationOptions<SuggestWorkOrderTextMutation, SuggestWorkOrderTextMutationVariables>;
export const SuggestKnowledgeBaseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SuggestKnowledgeBase"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"question"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestKnowledgeBase"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"question"},"value":{"kind":"Variable","name":{"kind":"Name","value":"question"}}}]}]}}]} as unknown as DocumentNode;
export type SuggestKnowledgeBaseMutationFn = Apollo.MutationFunction<SuggestKnowledgeBaseMutation, SuggestKnowledgeBaseMutationVariables>;

/**
 * __useSuggestKnowledgeBaseMutation__
 *
 * To run a mutation, you first call `useSuggestKnowledgeBaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestKnowledgeBaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestKnowledgeBaseMutation, { data, loading, error }] = useSuggestKnowledgeBaseMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useSuggestKnowledgeBaseMutation(baseOptions?: Apollo.MutationHookOptions<SuggestKnowledgeBaseMutation, SuggestKnowledgeBaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuggestKnowledgeBaseMutation, SuggestKnowledgeBaseMutationVariables>(SuggestKnowledgeBaseDocument, options);
      }
export type SuggestKnowledgeBaseMutationHookResult = ReturnType<typeof useSuggestKnowledgeBaseMutation>;
export type SuggestKnowledgeBaseMutationResult = Apollo.MutationResult<SuggestKnowledgeBaseMutation>;
export type SuggestKnowledgeBaseMutationOptions = Apollo.BaseMutationOptions<SuggestKnowledgeBaseMutation, SuggestKnowledgeBaseMutationVariables>;